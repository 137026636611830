import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Header from "../components/header/header.js";
import Image from "../components/image/image.js";
import ReadMore from "../components/readMore/readMore.js";
import FourArrows from "../components/fourArrows/fourArrowsIcon.js";

import "../assets/styles/pages/_our-integrations.scss";

const OurIntegration = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Header
        title="Le Nostre Integrazioni"
        metaDescription="La nostra suite dedicata al settore hospitality è un potente strumento che ti permetterà di prenderti cura del tuo cliente in ogni momento."
      />
      <div className="container ourIntegrations">
        <div className="columns">
          <div className="column">
            <span className="green bold">La Suite Hospitality</span>
          </div>
          <div className="column">
            <p className="description">
              La nostra suite dedicata al settore hospitality è un potente
              strumento che ti permetterà di prenderti cura del tuo cliente in
              ogni momento, assicurandogli un’assistenza trasversale.
              <br />
              <br />
              <br />
              Il sistema di prenotazione è rapido e garantisce di evitare
              l’overbooking, oltre ad assicurare il checkIN online.
              <br />
              <br />
              <br />
              L’utente potrà godere di una navigazione sicura, e al tempo
              stesso, tu sarai in grado di monitorare l’umore del tuo ospite e
              di far fronte alle sue esigenze.
              <br />
              <br />
              <br />
              Potrai creare il tuo database di contatti ed attivare attività
              promozionali ed azioni di marketing in base a target profilati.
              <br />
              <br />
              <br />
              Le recensioni positive saranno assicurate, insieme ad un aumento
              della brand reputation!
            </p>
            <div className="section" />
            <ReadMore to="/hospitality" text="Scopri di più" />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column ">
            <Image className="floatA" filename="do-not-disturb.png" alt="" />
            <div className="section" />
            <img
              src="https://static.acmeproduzioni.it/bookatme/logo.svg"
              alt="Logo Connect@You"
            />
          </div>
          <div className="column">
            <div className="arrow">
              <FourArrows direction={theme.isMobile ? "Down" : "Right"} />
            </div>
          </div>
          <div className="column">
            <Image className="floatB" filename="key-wifi.png" alt="" />
            <div className="section" />
            <img
              src="https://static.acmeproduzioni.it/connectatyou/logo.svg"
              alt="Logo Book@Me"
            />
          </div>
          <div className="column">
            <div className="arrow">
              <FourArrows direction={theme.isMobile ? "Down" : "Right"} />
            </div>
          </div>
          <div className="column">
            <Image className="floatC" filename="chat-star.png" alt="" />
            <div className="section" />
            <img
              src="https://static.acmeproduzioni.it/eucleia/logo.svg"
              alt="Logo Eucleia"
            />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <span className="azure bold">
              Integrazioni con Software di Terze Parti
            </span>
          </div>
          <div className="column">
            <p className="description">
              La suite Hospitality si completa con Isidoro Software, PMS per
              hôtellerie, con il quale gestiamo in comune oltre 40 clienti
              <br /> È semplice e di facile utilizzo, migliora l’affidabilità e
              la gestione della tua struttura.
              <br />
              <br />
              Le dashboard sono intuitive permettendo una pratica gestione di
              planning e prenotazioni da qualsiasi dispositivo.
              <br />
              L’integrazione tra tutti i nostri software rende agevole la
              gestione dell’utente in ogni fase, dalla prenotazione al check
              out.
              <br />
              <br />
              <h2>Statistiche e Revenue Management</h2>
              Grazie a Book@Me potrai monitorare e confrontare i dati (negli
              anni e per periodi) riguardo alle prenotazioni. Le statistiche ti
              aiuteranno a prendere le giuste decisioni per una strategia
              adeguata alla struttura: indici importanti per il Revenue
              Management, relativi alla disponibilità delle camere, numero
              camere vendute, la percentuale di occupazione (rapporto tra camere
              vendute e camere disponibili in un determinato periodo), il
              fatturato medio per camera/alloggio, etc. In questo modo potrai
              definire un piano tariffario adeguato che ti consenta di
              guadagnare degli utili, oltre a coprire le spese.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column is-3 is-offset-1 ">
            <Image filename="isidoro.png" alt="Isidoro Software" />
          </div>
          <div className="column is-3 ">
            <div className="arrow">
              <FourArrows direction={theme.isMobile ? "Down" : "Right"} />
            </div>
          </div>
          <div className="column is-3">
            <Image className="floatB" filename="puzzle.png" alt="Puzzle Icon" />
          </div>          
        </div>
      </div>
      <div className="section" />
    </>
  );
};

export default OurIntegration;
